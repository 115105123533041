import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EventsService extends DataService {

  constructor(http:HttpClient) {
    super(http);
   }

   getUpcomingEvents(pageNumber:number,pageSize:number){
    let request = {
      'eventSearchType':'Hosting',
      'filter':'',
      'pageNumber':pageNumber,
      'pageSize':pageSize
    }
    return this.post("admin/upcoming-events",request);
   }

   getPastEvents(pageNumber:number,pageSize:number){
    let request = {
      'eventSearchType':'Hosting',
      'filter':'',
      'pageNumber':pageNumber,
      'pageSize':pageSize
    }
    return this.post("admin/past-events",request);
   }



   getEventDetails(eventId?:string){    
    let request = {
      'eventSearchType':'Hosting',
      'filter':eventId,
      'pageNumber':0,
      'pageSize':0
    }
    return this.post("admin/event-detail",request);
   }

   
  getReportedEvents(pageNumber:number,pageSize:number){
    let request = {
      'eventSearchType':'Hosting',
      'filter':'',
      'pageNumber':pageNumber,
      'pageSize':pageSize
    }
    return this.post("admin/reported-events",request);
   }

}
