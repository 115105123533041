import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { EventListings } from '../models/event.listings';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { EventsService } from '../services/events.service';

@Component({
  selector: 'app-all-events',
  templateUrl: './all-events.component.html',
  styleUrl: './all-events.component.css'
})
export class AllEventsComponent implements OnInit {
  datePipe: DatePipe = new DatePipe('en-US');

  pageSize:number=50;  
  upcomingEvents: EventListings[] = [];


  totalItems = 0;
  currentPage = 0;
  smallnumPages = 0;

  constructor(private event:EventsService){}

  ngOnInit(): void {
    this.loadData(1);
  }

  private loadData(pageNumber:number){
    this.event.getPastEvents(pageNumber,this.pageSize).subscribe(
      (events:any)=>{          
        this.totalItems = events.payload.totalElements;        
        this.currentPage=pageNumber;
        this.upcomingEvents = events.payload?.elements;        
      }
    );

  }

  pageChanged(event: PageChangedEvent): void {
    this.loadData(event.page);
  }
  setPageSize(event:any){
    this.pageSize = event.target.value;    
    this.loadData(1);
  }

  formatDate(epochTime: number): string {
    const formattedDate = this.datePipe.transform(new Date(epochTime * 1000), 'EEE, dd MMM yyyy');
    return formattedDate || '';
  }

  formatTime(epochTime: number): string {
    const formattedDate = this.datePipe.transform(new Date(epochTime * 1000), 'hh:mm a');
    return formattedDate || '';
  }

  
}

