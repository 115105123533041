import { Component, ViewChild } from '@angular/core';
import { PollComponentComponent } from '../components/poll-component/poll-component.component';

@Component({
  selector: 'app-polls',
  templateUrl: './polls.component.html',
  styleUrl: './polls.component.css'
})
export class PollsComponent {
  pageSize:number=10;
  totalItems:number=0;

  @ViewChild(PollComponentComponent) child?:PollComponentComponent;

  setPageSize(event:any){
    this.pageSize = event.target.value;     
    if(this.child != null)
      this.child.pageSize = event.target.value;  
    this.child?.loadActiveTasks(1);  
  }

  receiveData(data: number) {
    this.totalItems = data;
  }
}
