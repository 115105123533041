import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.css'
})
export class NavComponent implements OnDestroy {
  
  
  currentUrl?: string;
  routerSubscription: any;

  constructor(private login:LoginService,private router: Router, private route: ActivatedRoute){        
    this.routerSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {      
      this.currentUrl = this.router.url;            
    });
  }
 
  logout(){
    this.login.logout();
    this.router.navigate(["/"]);
  }

  ngOnDestroy() {    
    this.routerSubscription.unsubscribe();
  }

  showNav(){    
    return this.login.isAuthenticated();    
  }
}
