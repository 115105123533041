<div class="row justify-content-center">
  <div class="col-4 center-column mx-auto">
      <div class="login-form">
          <h2 class="mb-3">Login</h2>
          <form class="login-block" #loginForm="ngForm">
            <div class="mb-3">
              <label for="email" class="form-label">Email</label>
              <input type="email" class="form-control" name="email" placeholder="Registered email id" [(ngModel)]="email" required email />
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input type="password" class="form-control" name="password" placeholder="Password" [(ngModel)]="password" required minlength="6" />
            </div>
            <div class="mb-3">
              <a href="#" class="text-decoration-none">Forgot password?</a>
            </div>
            <button type="submit" class="btn btn-primary" (click)="onClick()" [disabled]="loginForm.invalid">Login</button>
          </form>
        </div>
      
    </div>
</div>
