export class EventListings {
    activityCard: any;
    address: string;
    attendees: any;
    eventEndAt: number;
    eventGuid: string;
    eventHostGuidID: string;
    eventHostName: string;
    eventHostingStatus: string;
    eventLocationEnabled: boolean;
    eventMediaUrl: any;
    eventStartAt: number;
    eventStatus: string;
    id: number;
    isChatEnabled: boolean;
    isHost: boolean;
    isRecurring: boolean;
    latitude: string;
    locationSharingNotification: number;
    longitude: string;
    mediaCount: number;
    mediaInfo: any;
    participantAccepted: number;
    participantCount: number;
    poll: any;
    recurringMeta: any;
    tagLine: string;
    taskCount: number;
    taskReviewed: boolean;
    tasks: any;
    title: string;

    constructor(data: any = {}) {
        this.activityCard = data.activityCard || null;
        this.address = data.address || '';
        this.attendees = data.attendees || null;
        this.eventEndAt = data.eventEndAt || 0;
        this.eventGuid = data.eventGuid || '';
        this.eventHostGuidID = data.eventHostGuidID || '';
        this.eventHostName = data.eventHostName || '';
        this.eventHostingStatus = data.eventHostingStatus || '';
        this.eventLocationEnabled = data.eventLocationEnabled || false;
        this.eventMediaUrl = data.eventMediaUrl || null;
        this.eventStartAt = data.eventStartAt || 0;
        this.eventStatus = data.eventStatus || '';
        this.id = data.id || 0;
        this.isChatEnabled = data.isChatEnabled || false;
        this.isHost = data.isHost || false;
        this.isRecurring = data.isRecurring || false;
        this.latitude = data.latitude || '';
        this.locationSharingNotification = data.locationSharingNotification || 0;
        this.longitude = data.longitude || '';
        this.mediaCount = data.mediaCount || 0;
        this.mediaInfo = data.mediaInfo || null;
        this.participantAccepted = data.participantAccepted || 0;
        this.participantCount = data.participantCount || 0;
        this.poll = data.poll || null;
        this.recurringMeta = data.recurringMeta || null;
        this.tagLine = data.tagLine || '';
        this.taskCount = data.taskCount || 0;
        this.taskReviewed = data.taskReviewed || false;
        this.tasks = data.tasks || null;
        this.title = data.title || '';
    }
}
