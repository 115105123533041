<div class="container-fluid p-3">
    <div class="row" style="margin: 0 15px 0 0;">
      <div class="col-11">
        <h6 class="alert p-2 mb-1">Past Events ({{totalItems}})</h6>
      </div>    
      <div class="col">
        <select [(ngModel)]="pageSize" class="form-select" aria-label="Default select example" (change)="setPageSize($event)">
            <option [value]="50">50</option>
            <option [value]="100">100</option>
            <option [value]="500">500</option>
            <option [value]="1000">1000</option>
        </select>
      </div>
      <div class="col-12 upcoming-events p-2">     
        <div class="row">
            <div class="col-8">&nbsp;</div>
            <div class="col-4">
              <form class="">
                <div class="row mt-2">
                  <div class="col-10">
                    <div class="form-group mx-sm-3 mb-2">                    
                      <input type="text" class="form-control" placeholder="Search By Title">
                    </div>    
                  </div>
                  <div class="col-2">                    
                    <button type="submit" class="btn btn-primary mb-2"><i class="bi bi-search"></i></button>
                  </div>
                </div>  
              </form>    
            </div>      
        </div> 
        <hr />   
        <table class="table table-custom">
            <thead>
                <tr>
                    <th>Event Title</th>
                    <th>Address</th>
                    <th>Host</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Attendees</th>
                    <th>Tasks</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let event of upcomingEvents">
                    <td><img *ngIf="event.eventMediaUrl; else defaultImage" [src]="event.eventMediaUrl" class="missing-image" alt="">
                        <ng-template #defaultImage>
                          <img src="assets/images/event_details_bg_default.png" class="missing-image" alt="">
                        </ng-template>
                        <a class="link" [routerLink]="['/event-detail', event.eventGuid]">{{event.title}}</a></td>
                    <td>{{event.address}}</td>
                    <td><a class="link" [routerLink]="['/profile', event.eventHostGuidID]">{{event.eventHostName}}</a></td>
                    <td>{{formatDate(event.eventStartAt)}}</td>
                    <td>{{formatTime(event.eventStartAt)}}</td>
                    <td>{{event.participantCount}}</td>
                    <td>{{event.taskCount}}</td>
                </tr>                                                  
            </tbody>            
        </table>
        <pagination [boundaryLinks]="true" [maxSize]="10" [totalItems]="totalItems" [itemsPerPage]="pageSize" (pageChanged)="pageChanged($event)"
            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
 
</pagination>

    </div>    
    </div>
</div>