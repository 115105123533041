<div class="container-fluid p-3">
  <div class="col-12">
    <div class="row">
      <div class="col-8">
        <h6 class="alert p-2 mb-1">{{ response.title }}</h6>
      </div>
      <div class="col">
        <button class="btn btn-info btn-back btn-sm" (click)="goBack()"><i class="bi bi-skip-backward-circle"></i> Back</button>
      </div>      
    </div>
  </div>
  <div class="col-12 upcoming-events p-2">
    <div class="row">
      <div class="col-4">
        <!-- Display image -->
        <div class="card">
          <div class="card-body p-1">
            <img *ngIf="response.eventMediaUrl; else defaultImage" [src]="response.eventMediaUrl" class="img-fluid rounded w-100" alt="">
            <ng-template #defaultImage>
              <img src="assets/images/event_details_bg_default.png" class="img-fluid rounded w-100" alt="">
            </ng-template>
          </div>
        </div> <!-- Display image - close -->
        <div class="card mt-2">
          <div class="card-body p-3">
              <p class="p-0 m-0 mb-1"><i class="bi bi-clock-fill"></i> <strong> {{formatTime(response.eventStartAt)}}</strong> <span> {{formatDate(response.eventStartAt)}}</span></p>
              <p class="p-0"><i class="bi bi-geo-alt-fill"></i> <span>{{response.address}}</span></p>
              <strong class="m-0 mt-5 mb-2">Tagline</strong>
              <p>{{response.tagLine}}</p>
          </div>
        </div>
        <button (click)="openModal(cancelEvent);" class="btn btn-danger w-100 mt-3" *ngIf="response.eventStatus == 'UpComing'">Delete Event</button>
      </div>
      <div class="col">
        <!-- first row -->
          <div class="row">
            <div class="col-4">
              <div class="card totalCounts">
                <div class="card-body">
                  <h5 class="card-title">Tasks <i class="bi bi-list-task"></i></h5>
                  <p class="card-text">{{response.taskCount}}</p>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="card totalCounts">
                <div class="card-body">
                  <h5 class="card-title">Attendees <i class="bi bi-person-check-fill"></i></h5>
                  <p class="card-text">{{response.participantCount}}</p>
                </div>
              </div>              
            </div>
            <!-- <div class="col-4">
              <div class="card totalCounts">
                <div class="card-body">
                  <h5 class="card-title">Polls <i class="bi bi-bar-chart-line"></i></h5>
                  <p class="card-text">{{totalPolls}}</p>
                </div>
              </div>    
            </div> -->
          </div>
          <!-- close -->  
          <!-- tasks start  -->      
          <div class="row" *ngIf="response.tasks != null">
            <div class="col-12">
              <h6 class="alert p-2 mb-3 mt-4">Tasks</h6>
            </div>
            <div class="col-6  mb-3" *ngFor="let task of response.tasks">
              <div class="card task">
                <div class="card-body task-card-body">
                  <h5 class="card-title task-card-title">{{task.description}}</h5>
                  <div class="task-content-row">
                    <img *ngIf="task.profileImage; else defaultImage" [src]="task.profileImage" class="task-rounded-image" alt="">
            <ng-template #defaultImage>
              <img src="assets/images/user_default.png" alt="Image" class="task-rounded-image">
            </ng-template>
                    
                    <p class="card-text task-card-text">{{ (task.assignedToId == null)? 'Unassigned' : task.assignedToName}}</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- tasks close  -->      
          <!-- Activity start  -->      
          <!-- <div class="row" *ngIf="response.activityCard != null">
            <div class="col-12">
              <h6 class="alert p-2 mb-3 mt-2">Activity Cards</h6>
            </div>
            <div class="col-6  mb-3" *ngFor="let activity of response.activityCard">
              
              <div class="card activityCard" style="background-image: url('assets/badges/bg/{{activity.badge.toLowerCase()}}.png');">
                <div class="card-body activityCard-body">
                  <div class="activityCard-content">
                    <div class="activityCard-text-content">
                      <h5 class="card-title activityCard-title">{{activity.title}}</h5>
                      <p class="card-text activityCard-text">{{activity.description}}</p>
                    </div>
                    <img src="assets/badges/{{activity.badge.toLowerCase()}}.png" alt="Image" class="activityCard-rounded-image">
                  </div>
                </div>
              </div>

            </div>
          </div> -->
          <!-- Activity close  -->      
          <!-- attendees start  -->      
          <div class="row" *ngIf="response.attendees != null">
            <div class="col-12">
              <h6 class="alert p-2 mb-3 mt-2">Attendees</h6>
            </div>
            <div class="col-6 mb-3" *ngFor="let attendee of response.attendees">

              <div class="card attendees">
                <div class="card-body attendees-body">
                  <img *ngIf="attendee.profileImage; else defaultImage" [src]="attendee.profileImage" class="img-fluid attendees-rounded-image" alt="{{attendee.username}}">
                  <ng-template #defaultImage>
                    <img src="assets/images/user_default.png" class="img-fluid attendees-rounded-image" alt="{{attendee.username}}">
                  </ng-template>
                  <div class="attendees-text-content">
                    <h5 class="card-title attendees-title">{{attendee.firstName}}</h5>
                    <p class="card-text attendees-text" *ngIf="attendee.username != null">{{attendee.username}}</p>
                    <p class="card-text attendees-text" *ngIf="attendee.username == null">{{(attendee.phoneNumber != null) ? attendee.phoneNumber : attendee.emailId }}</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- attendees close  -->      
          <!-- Polls start  -->      
          <!-- <div class="row" *ngIf="response.poll != null">
            <div class="col-12">
              <h6 class="alert p-2 mb-3 mt-2">Polls</h6>
            </div>
            <div class="col-6 mb-3" *ngFor="let poll of response.poll">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">{{poll.question}}</h5>
                  <ul class="vote-list">
                    <li *ngFor="let opt of poll.options" class="vote-item">
                      <span class="option-name">{{opt.option}}</span>
                      <span class="percentage">{{getPercentage(opt.totalVote,getTotalVotes(poll.options)) | number:'1.0-2'}}%</span>
                      <div class="progress-bar">
                        <div class="progress" [style.width.%]="getPercentage(opt.totalVote,getTotalVotes(poll.options)) | number:'1.0-2'"></div>
                      </div>                      
                    </li>
                  </ul>                 
                </div>
              </div>

            </div>
          </div> -->
          <!-- Polls close  -->                      
      </div>
    </div> 
  </div>
</div>


<ng-template #cancelEvent>
  <div class="modal-body text-center">
    <div class="modal-header p-0">
      <h6 class="modal-title pull-left">Cancel Event</h6>
    </div>
    <div class="modal-body p-0">
      <div class="row justify-content-center mt-3 mb-1">       
          <form class="col-12 center-column mx-auto" #loginForm="ngForm">
            <div class="row">
                <div class="mb-3 col-12">
                    <textarea
                      class="form-control"
                      name="block-content"
                      placeholder="Reason to cancel the event"
                      required
                      [(ngModel)]="message"
                    ></textarea>
                  </div>
            </div>
            <div class="row">
                  <div class="col-6">
                      <button
                      type="submit"
                      class="btn btn-success float-start btn-sm"
                      (click)="confirm()"
                      [disabled]="loginForm.invalid"
                    >
                      Submit
                    </button>              
                  </div>     
                  <div class="col-6">
                      <button type="button" class="btn btn-danger float-end btn-sm" (click)="decline()">Cancel</button>
                  </div>       
            </div>
            
          </form>
        
      </div>
    </div>

  </div>
</ng-template>

