import { Component, ViewChild } from '@angular/core';
import { ActiveTasksComponent } from '../components/active-tasks/active-tasks.component';

@Component({
  selector: 'app-all-tasks',
  templateUrl: './all-tasks.component.html',
  styleUrl: './all-tasks.component.css'
})
export class AllTasksComponent {
  pageSize:number=10;
  totalItems:number=0;

  @ViewChild(ActiveTasksComponent) child?:ActiveTasksComponent;

  setPageSize(event:any){
    this.pageSize = event.target.value;     
    if(this.child != null)
      this.child.pageSize = event.target.value;  
    this.child?.loadActiveTasks(1);  
  }

  receiveData(data: number) {
    this.totalItems = data;
  }

}
