<nav class="navbar navbar-expand-lg navbar-light bg-light" *ngIf="showIfAuthenticated()">
    <div class="container-fluid">
      <!-- Left side: Search input -->
      <!-- <form class="d-flex">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">            
      </form> -->
  
      <!-- Right side: User dropdown -->
      <div class="dropdown ms-auto">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="userDropdown" data-bs-toggle="dropdown" aria-expanded="false">
          <img *ngIf="profileImage; else defaultImage" [src]="profileImage" class="img-fluid" alt="">
              <ng-template #defaultImage>
                <img src="assets/images/user_default.png" class="img-fluid" alt="">
              </ng-template>          
          <label>{{UserName}}</label>
        </button>
        <ul class="dropdown-menu" aria-labelledby="userDropdown">
          <li><a class="dropdown-item" routerLink="profile/{{userId}}">Profile</a></li>
          <li><hr class="dropdown-divider"></li>
          <li><a class="dropdown-item" routerLink="admin">Admin Users</a></li>
          <li><hr class="dropdown-divider"></li>
          <li><a class="dropdown-item" href="javascript:;" (click)="logout()">Logout</a></li>
        </ul>
      </div>
    </div>
  </nav>
  