<div class="container-fluid h-100">
  <div class="row h-100">
    <nav class="col-md-3 col-lg-2 d-md-block sidebar left-panel-login">
      <app-nav></app-nav>
    </nav>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4 content-color reset-padding">
      <app-top-search-bar></app-top-search-bar>
      <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
      <router-outlet>    
      </router-outlet>               
    </main>      
  </div>
</div>
