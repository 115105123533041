import { Component, OnInit, TemplateRef } from '@angular/core';
import { EventListings } from '../models/event.listings';
import { DatePipe } from '@angular/common';
import { EventsService } from '../services/events.service';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-upcoming-events',
  templateUrl: './upcoming-events.component.html',
  styleUrl: './upcoming-events.component.css'
})
export class UpcomingEventsComponent implements OnInit {

  upcomingEvents: EventListings[] = [];
  datePipe: DatePipe = new DatePipe('en-US');
  
  pageSize:number=25;

  totalItems = 0;
  currentPage = 0;
  smallnumPages = 0;

  modalRef?: BsModalRef;
  message?: string;  

  constructor(private event:EventsService,private modalService:BsModalService){}

  ngOnInit(): void {
    this.loadData(1);
  }

  private loadData(pageNumber:number){
    this.event.getUpcomingEvents(pageNumber,this.pageSize).subscribe(
      (events:any)=>{          
        this.totalItems = events.payload.totalElements;        
        this.currentPage=pageNumber;
        this.upcomingEvents = events.payload?.elements;        
      }
    );

  }

  pageChanged(event: PageChangedEvent): void {
    this.loadData(event.page);
  }
  setPageSize(event:any){
    this.pageSize = event.target.value;    
    this.loadData(1);
  }

  formatDate(epochTime: number): string {
    const formattedDate = this.datePipe.transform(new Date(epochTime * 1000), 'EEE, dd MMM yyyy');
    return formattedDate || '';
  }

  formatTime(epochTime: number): string {
    const formattedDate = this.datePipe.transform(new Date(epochTime * 1000), 'hh:mm a');
    return formattedDate || '';
  }



}
