import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NavComponent } from './nav/nav.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TopSearchBarComponent } from './top-search-bar/top-search-bar.component';
import { AllEventsComponent } from './all-events/all-events.component';
import { AllTasksComponent } from './all-tasks/all-tasks.component';
import { PollsComponent } from './polls/polls.component';
import { UpcomingEventsComponent } from './upcoming-events/upcoming-events.component';
import { AllUsersComponent } from './all-users/all-users.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ActiveTasksComponent } from './components/active-tasks/active-tasks.component';
import { PollComponentComponent } from './components/poll-component/poll-component.component';
import { FormatNumbers } from './validator/format.numbers';
import { EventDetailComponent } from './components/event-detail/event-detail.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { ReportedEventsComponent } from './reported-events/reported-events.component';
import { BlockedUsersComponent } from './blocked-users/blocked-users.component';
import { AdminComponent } from './admin/admin.component';
import { ProfileComponent } from './profile/profile.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavComponent,
    DashboardComponent,
    TopSearchBarComponent,
    AllEventsComponent,
    AllTasksComponent,
    PollsComponent,
    UpcomingEventsComponent,
    AllUsersComponent,
    ActiveTasksComponent,
    PollComponentComponent,
    FormatNumbers,
    EventDetailComponent,
    ReportedEventsComponent,
    BlockedUsersComponent,
    AdminComponent,
    ProfileComponent    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    PaginationModule.forRoot() ,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    NgxSpinnerModule
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  exports:[
    PaginationModule,
    ModalModule,
    FormatNumbers
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptor,
    multi: true
}],
  bootstrap: [AppComponent]
})
export class AppModule { }
