import { Component, NgZone, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { Auth } from '../models/auth.request';
import { Router } from '@angular/router';
 
 
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {
  email:string='';
  password:string='';
 
  auth:Auth={
    email:this.email,
    password:this.password
  };
 
  constructor(private data:LoginService,private router:Router){
 
  }
 
  ngOnInit() {
   if(this.data.isAuthenticated())
      this.router.navigate(['dashboard']);
  }
 
  onClick(){   
    this.auth =  {
      email:this.email,
      password:this.password
    }
    this.data.authorize(this.auth);  
    
  }
 
}
 
 
 
 