import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { UsersService } from '../services/users.service';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.css'
})
export class AdminComponent {
  datePipe: DatePipe = new DatePipe('en-US');
  pageSize:number=50;  
  users: any[] = [];


  totalItems = 0;
  currentPage = 0;
  smallnumPages = 0;

  modalRef?: BsModalRef;
  
  subject?:string
  message?:string;
  blockContent?:string;

  constructor(private user:UsersService,private modalService:BsModalService){}
  

  ngOnInit(): void {   
   this.loadData(1);
  }
  private loadData(pageNumber:number){
    this.user.getAdminUsers(1,this.pageSize).subscribe(
      (users:any)=>{                   
          this.totalItems = users.payload.totalElements;        
          this.currentPage=pageNumber;
          this.users = users.payload?.elements;    
      }
    );
  }

  pageChanged(event: PageChangedEvent): void {
    this.loadData(event.page);
  }
  setPageSize(event:any){
    this.pageSize = event.target.value;    
    this.loadData(1);
  }

  formatDate(epochTime: number): string {
    const formattedDate = this.datePipe.transform(new Date(epochTime * 1000), 'EEE, dd MMM yyyy');
    return formattedDate || '';
  }
  
  openModal(template: TemplateRef<void>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  notifyUser(): void {          
    this.subject=""; 
    this.message="";
    this.modalRef?.hide();
  }
 

  block(): void {   
    this.blockContent="";        
    this.modalRef?.hide();
  }
 
  decline(): void {    
    this.modalRef?.hide();
  }

}
