import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PollsService extends DataService {
  constructor(http:HttpClient) { super(http); }

  getActivePolls(pageNumber:number,pageSize:number){
    let request = {
      'eventSearchType':'Hosting',
      'filter':'',
      'pageNumber':pageNumber,
      'pageSize':pageSize
    }
    return this.post("admin/active-polls",request);
   }


}

