import { Component, OnInit, TemplateRef } from '@angular/core';
import { BlockedUsersService } from '../services/blocked-users.service';
import { BlockedUsers } from '../models/blocked.users';
import { DatePipe } from '@angular/common';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-blocked-users',
  templateUrl: './blocked-users.component.html',
  styleUrl: './blocked-users.component.css'
})
export class BlockedUsersComponent implements OnInit {
  datePipe: DatePipe = new DatePipe('en-US');
  pageSize:number=50;  
  totalItems = 0;
  currentPage = 0;

  users: BlockedUsers[] = [];
  modalRef?: BsModalRef;
  subject?:string
  message?:string;
  blockContent?:string;
  
  constructor(private blockedUser:BlockedUsersService,private modalService:BsModalService){}


  ngOnInit(): void {
    this.loadData(1);
  }

  loadData(pageNumber:number){
    this.blockedUser.getBlockedUsers(pageNumber,this.pageSize).subscribe(
      (users:any)=>{          
        this.totalItems = users.payload.totalElements;        
        this.currentPage=pageNumber;
        this.users = users.payload.elements;
        console.log(this.users);
      }
    );

  }

  pageChanged(event: PageChangedEvent): void {
    this.loadData(event.page);
  }
  setPageSize(event:any){
    this.pageSize = event.target.value;    
    this.loadData(1);
  }

  formatDate(epochTime: number): string {
    const formattedDate = this.datePipe.transform(epochTime, 'EEE, dd MMM yyyy');
    return formattedDate || '';
  }
  openModal(template: TemplateRef<void>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }
  
  block(): void {   
    this.blockContent="";        
    this.modalRef?.hide();
  }
 
  decline(): void {    
    this.modalRef?.hide();
  }


}
