<div class="container-fluid p-3">
  <div class="row" style="margin: 0 15px 0 0;">
    <div class="col-11">
      <h6 class="alert p-2 mb-1">Users ({{ totalItems }})</h6>
    </div>
    <div class="col">
      <select
        [(ngModel)]="pageSize"
        class="form-select"
        aria-label="Default select example"
        (change)="setPageSize($event)"
      >
        <option [value]="50">50</option>
        <option [value]="100">100</option>
        <option [value]="500">500</option>
        <option [value]="1000">1000</option>
      </select>
    </div>
    <div class="col-12 upcoming-events p-2">
      <div class="row">
        <div class="col-8">&nbsp;</div>
        <div class="col-4">
          <form class="">
            <div class="row mt-2">
              <div class="col-10">
                <div class="form-group mx-sm-3 mb-2">                    
                  <input type="text" class="form-control" placeholder="Search By Mobile">
                </div>    
              </div>
              <div class="col-2">                    
                <button type="submit" class="btn btn-primary mb-2"><i class="bi bi-search"></i></button>
              </div>
            </div>  
          </form>    
        </div>      
    </div> 
    <hr />
      <table class="table table-custom">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Gender</th>
            <th>Country</th>
            <th>Added On</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of users">
            <td><img *ngIf="user.profileImage; else defaultImage" [src]="user.profileImage" class="missing-image" alt="">
              <ng-template #defaultImage>
                <img src="assets/images/user_default.png" class="missing-image" alt="">
              </ng-template>                             
              <a class="link" [routerLink]="['/profile', user.guid]">{{ user.firstName }}</a>              
              <img src="assets/images/business-profile.png" *ngIf="(!user.businessProfiles || user.businessProfiles.length > 0)" class="img-fluid business-profile" alt="">
            </td>
            <td>{{ user.email }}</td>
            <td>{{ user.phoneNumber }}</td>
            <td>{{ user.gender?.name }}</td>
            <td>{{ user.country?.name }}</td>
            <td>{{ formatDate(user.createdOn) }}</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn btn-default dropdown-toggle btn-sm"
                  type="button"
                  id="userDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                <i class="bi bi-three-dots-vertical"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="userDropdown">
                  <li>
                    <a
                      class="dropdown-item"
                      (click)="openModal(blockUser)"
                      href="javascript:;"
                      >Block User</a
                    >
                  </li>                  
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination
        [maxSize]="10"
        [boundaryLinks]="true"
        [totalItems]="totalItems"
        [itemsPerPage]="pageSize"
        (pageChanged)="pageChanged($event)"
        previousText="&lsaquo;"
        nextText="&rsaquo;"
        firstText="&laquo;"
        lastText="&raquo;"
      >
      </pagination>
    </div>
  </div>
</div>
<ng-template #blockUser>
  <div class="modal-body text-center">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Block User</h4>
    </div>
    <div class="modal-body">
      <div class="row justify-content-center">       
          <form class="col-12 center-column mx-auto" #blockUserForm="ngForm">
            <div class="row">
                <div class="mb-3 col-12">
                    <textarea
                      class="form-control"
                      name="block-content"
                      [(ngModel)]="blockContent"
                      placeholder="Reason to block the user"
                      required
                    ></textarea>
                  </div>
            </div>
            <div class="row">
                  <div class="col-6">
                      <button
                      type="submit"
                      class="btn btn-success float-start btn-sm"
                      (click)="block()"
                      [disabled]="blockUserForm.invalid"
                    >
                      Block
                    </button>              
                  </div>     
                  <div class="col-6">
                      <button type="button" class="btn btn-danger float-end btn-sm" (click)="decline()">Cancel</button>
                  </div>       
            </div>
            
          </form>
        
      </div>
    </div>

  </div>
</ng-template>

<ng-template #sendNotification>
    <div class="modal-body text-center">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Notify User</h4>
      </div>
      <div class="modal-body">
        <div class="row justify-content-center">       
            <form class="col-12 center-column mx-auto" #sendNotificationForm="ngForm">
              <div class="row">
                <div class="mb-3 col-12">
                    <input type="text"
                      class="form-control"
                      name="subject"
                      placeholder="Subject"
                      [(ngModel)]="subject"
                      required
                    />
                  </div>
            </div>              
              <div class="row">
                  <div class="mb-3 col-12">
                      <textarea
                        class="form-control"
                        name="block-content"
                        placeholder="Message"
                        [(ngModel)]="message"
                        required
                      ></textarea>
                    </div>
              </div>
              <div class="row">
                    <div class="col-6">
                        <button
                        type="submit"
                        class="btn btn-primary float-start btn-sm"
                        (click)="notifyUser()"
                        [disabled]="sendNotificationForm.invalid"
                      >
                        Send
                      </button>              
                    </div>     
                    <div class="col-6">
                        <button type="button" class="btn btn-danger float-end btn-sm" (click)="decline()">Cancel</button>
                    </div>       
              </div>
              
            </form>
          
        </div>
      </div>
  
    </div>
  </ng-template>
