 <div class="position-sticky" *ngIf="showNav()">
    <div class="row mt-3 mb-5">
        <div class="col-3">
            <img src="/assets/images/inner-logo.png" class="img-fluid" />
        </div>
        <div class="col-9">
            <label class="logo-text">Waasl</label>
        </div>
    </div>
  <ul class="nav flex-column">
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': currentUrl=='/dashboard', 'de-active': currentUrl!='/dashboard'}" routerLink="/dashboard"><i class="bi bi-grid-fill"></i> <span>Dashboard</span></a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': currentUrl=='/upcoming-events', 'de-active': currentUrl!='/upcoming-events'}" routerLink="/upcoming-events"><i class="bi bi-calendar-week-fill"></i> <span>Upcoming events</span></a>
    </li>    
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': currentUrl=='/all-events', 'de-active': currentUrl!='/all-events'}" routerLink="/all-events"><i class="bi bi-calendar-minus"></i> <span>Past Events</span></a>
    </li>    
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': currentUrl=='/all-tasks', 'de-active': currentUrl!='/all-tasks'}" routerLink="/all-tasks"><i class="bi bi-list-task"></i> <span>All Tasks</span></a>
    </li>    
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': currentUrl=='/all-users', 'de-active': currentUrl!='/all-users'}" routerLink="/all-users"><i class="bi bi-person-check-fill"></i> <span>All Users</span></a>
    </li>    
    <!-- <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': currentUrl=='/polls', 'de-active': currentUrl!='/polls'}" routerLink="/polls"><i class="bi bi-bar-chart-line"></i> <span>Polls</span></a>
    </li>    -->
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': currentUrl=='/blocked-users', 'de-active': currentUrl!='/blocked-users'}" routerLink="/blocked-users"><i class="bi bi-person-fill-slash"></i> <span>Blocked Users</span></a>
    </li>        
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active': currentUrl=='/reported-events', 'de-active': currentUrl!='/reported-events'}" routerLink="/reported-events"><i class="bi bi-flag-fill"></i> <span>Reported Events</span></a>
    </li>        
  </ul>
</div>
 
 <div class="login" *ngIf="!showNav()">
    <img src="/assets/images/logo.png" class="img-fluid logo" />  
    <img src="/assets/images/login-bg.png" class="img-fluid" />  
  </div>
