import { environment } from './../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
 
@Injectable({
  providedIn: 'root'
})
export class DataService {
 
  private headers: HttpHeaders | undefined;
 
   private baseUrl:string;
  constructor(private http:HttpClient) {     
    this.baseUrl = environment.API_BASE_URL;
    console.log(this.baseUrl);
    this.setHeaders();
  }
 
  setHeaders() {
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', 'application/json');
    if(!!localStorage.getItem("auth_token"))
      this.headers = this.headers.set('Authorization', `Bearer ${localStorage.getItem("auth_token")}`);
  }
  
  getAll(resourceUrl:string):Observable<HttpResponse<any>>{
    this.setHeaders();
    return this.http.get<any>(this.baseUrl+'/'+resourceUrl,{ headers: this.headers }).pipe(catchError(this.handleError));
  }
 
  post(resourceUrl:string,resource:any):Observable<HttpResponse<any>>{      
      return this.http.post<any>(this.baseUrl+'/'+resourceUrl,JSON.stringify(resource),{ headers: this.headers }).pipe(catchError(this.handleError));
  }
 
  update(resourceUrl:string,resource:any){
    return this.http.put(this.baseUrl+'/'+resourceUrl,JSON.stringify(resource),{ headers: this.headers }).pipe(catchError(this.handleError));
   }
 
  delete(resourceUrl:string,id:any){
    return this.http.delete(this.baseUrl+'/'+resourceUrl,{ headers: this.headers }).pipe(catchError(this.handleError));
  }
 
  private handleError(error: HttpErrorResponse) {
    
    if (error.error instanceof ErrorEvent) {      
      console.error('An error occurred:', error.error.message);
    } 
    else
    {
      console.error(error.error);
      console.error(error.status)
    }
 
    if (error.error instanceof ErrorEvent)
      console.error('An error occurred:', error.error.message);
    else
      console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
  
    return throwError('Something bad happened; please try again later.');
  }
 
}