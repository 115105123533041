<div class="container-fluid p-3">
  <div class="row" style="margin: 0 15px 0 0;">
    <div class="col-11">
      <h6 class="alert p-2 mb-1">All Polls</h6>
    </div>
    <div class="col-12 upcoming-events p-2">
      <app-poll-component [totalCols]="4" [totalPolls]="0"></app-poll-component>
    </div>
  </div>
</div>
