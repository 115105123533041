import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends DataService {

  constructor(http:HttpClient) {
    super(http);
   }

   getDashboard(){
    return this.getAll("admin/dashboard");
   }

   getActiveTasks(pageNumber:number,pageSize:number){
    let request = {
      'eventSearchType':'Hosting',
      'filter':'',
      'pageNumber':pageNumber,
      'pageSize':pageSize
    }
    return this.post("admin/active-tasks",request);
   }

}
