<div class="container-fluid p-3">
  <div class="row" style="margin: 0 15px 0 0;">
    <div class="col-11">
      <h6 class="alert p-2 mb-1">All Tasks ({{totalItems}})</h6>
    </div>
    <div class="col">
      <select
        [(ngModel)]="pageSize"
        class="form-select"
        aria-label="Default select example"
        (change)="setPageSize($event)"
      >
        <option [value]="10">10</option>
        <option [value]="25">25</option>
        <option [value]="50">50</option>
        <option [value]="100">100</option>
      </select>
    </div>
    <div class="col-12 upcoming-events p-2">
      <app-active-tasks (sendTotalItems)="receiveData($event)"></app-active-tasks>
    </div>
  </div>
</div>
