export class PollOption {
    id: number;
    option: string;
    totalVote: number;
    
    constructor(data: any) {
        this.id = data.id;
        this.option = data.option;
        this.totalVote = data.totalVote;
      }
}
