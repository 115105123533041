
import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { EventListings } from '../models/event.listings';
import { DatePipe, formatNumber } from '@angular/common';
import { FormatNumbers } from '../validator/format.numbers';
import { SharedUserInfoService } from '../services/shared-user-info.service';
import { LoginService } from '../services/login.service';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements OnInit {
  totalEvents:number=0;
  totalPastEvents:number=0;
  totalUpcomingEvents:number=0;
  totalTasks:number=0;
  totalUsers:number=0;
  totalPolls:number=0;
  totalBlockedUsers:number=0;
  totalReportedEvents:number=0;
  upcomingEvents: EventListings[] = [];
  datePipe: DatePipe = new DatePipe('en-US');


  ngOnInit(): void {
    this.loadDashboard();    

    //Update top nav
    this.login.getLoggedInUser().subscribe((user: any) => {
      if(user.payload != null){
        this.sharedInfo.emitEvent(user.payload);  
      }
    }); 
    
  }



  private loadDashboard(){
    this.dashboard.getDashboard().subscribe(
      (dashboard:any)=>{
        this.totalEvents=dashboard.payload?.totalEvents;
        this.totalPastEvents=dashboard.payload?.totalPastEvents;
        this.totalUpcomingEvents=dashboard.payload?.totalUpcomingEvents;
                
        this.totalTasks=dashboard.payload?.totalTasks;
        this.totalUsers=dashboard.payload?.totalUsers;
        this.totalPolls=dashboard.payload?.totalPolls;
        this.totalReportedEvents=dashboard.payload?.totalReportedEvents;
        this.totalBlockedUsers=dashboard.payload?.totalBlockedUsers;
        this.upcomingEvents = dashboard.payload?.upcomingEvents?.elements;        
      }
    );
  }


  constructor(private dashboard:DashboardService, private sharedInfo:SharedUserInfoService,private login:LoginService){}

  formatDay(epochTime: number): string {
    const formattedDate = this.datePipe.transform(new Date(epochTime * 1000), 'd');
    return formattedDate || '';    
  }

  formatMonth(epochTime: number): string {
    const formattedDate = this.datePipe.transform(new Date(epochTime * 1000), 'MMM');
    return formattedDate || '';
  }

  formatTime(epochTime: number): string {
    const formattedDate = this.datePipe.transform(new Date(epochTime * 1000), 'h:mm a');
    return formattedDate || '';
  }

}
