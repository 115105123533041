import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoginService } from '../services/login.service';
import {  Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedUserInfoService } from '../services/shared-user-info.service';

@Component({
  selector: 'app-top-search-bar',
  templateUrl: './top-search-bar.component.html',
  styleUrl: './top-search-bar.component.css',
})
export class TopSearchBarComponent implements OnInit, OnDestroy {



  UserName: string;
  profileImage: string;
  userId:string;

  message: string;
  private subscription: Subscription;



  constructor(private login: LoginService, private router: Router,private sharedInfo:SharedUserInfoService) {
    this.UserName = "";
    this.profileImage = "";
    this.userId="";
    this.message = "";
    this.subscription = new Subscription();
  }
  
  ngOnInit(): void {
    this.subscription = this.sharedInfo.event$.subscribe(data => {
      this.UserName = data.name;
      this.profileImage = data.profileImg;
      this.userId = data.guid   
    });      
    if(this.login.isAuthenticated())   
      this.loadProfile();
  }

  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }




  logout() {
    this.login.logout();
    this.router.navigate(['/']);
  }

  showIfAuthenticated() {  
    return this.login.isAuthenticated();
  }
  
  private loadProfile() {
    this.login.getLoggedInUser().subscribe((user: any) => {
      if(user.payload != null){
        this.UserName = user.payload?.name;
        this.profileImage = user.payload?.profileImg;
        this.userId = user.payload?.guid       
      }
    });
  }

}
