export class UserProfile {
  public guid?: string
  public userName?: string
  public email?: string;
  public mobile?: string;
  public name?: string;
  public country_id?: number;
  public gender_id?: number;
  public stateId?: number ;
  public cityId?: number ;
  public country?: string ;
  public stateName?: string ;
  public cityName?: string ;
  public gender?: string ;
  public profileImg?: string ;
  public terms?: string ;
  public bio?: string ;
  public businessProfile?: string ;
}
