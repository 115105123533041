import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { ActiveTasks } from '../../models/active.tasks';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-active-tasks',
  templateUrl: './active-tasks.component.html',
  styleUrl: './active-tasks.component.css'
})

export class ActiveTasksComponent implements OnInit {

  activeTasks:ActiveTasks[] = [];

  @Output() sendTotalItems = new EventEmitter<number>();
  @Input() showPagination:boolean=false;
  public pageSize:number=10;

  totalItems = 0;
  currentPage = 0;
  smallnumPages = 0;

  hideIfNotNeeded:boolean=true;

  constructor(private dashboard:DashboardService){}

  ngOnInit(): void {
    this.loadActiveTasks(1);  
    this.hideIfNotNeeded=this.showPagination;
  }

  public loadActiveTasks(pageNumber:number){
    this.dashboard.getActiveTasks(pageNumber,this.pageSize).subscribe(
      (tasks:any)=>{        
        this.activeTasks=tasks.payload?.elements;
        this.totalItems = tasks.payload.totalElements;        
        this.currentPage=pageNumber;        
        this.sendTotalItems.emit(this.totalItems);
        if(this.pageSize>this.totalItems)
          this.hideIfNotNeeded=false;
      }
    );
  }

  pageChanged(event: PageChangedEvent): void {
    this.loadActiveTasks(event.page);
  }

}
