import { ReportedEventsComponent } from './reported-events/reported-events.component';
import { PollsComponent } from './polls/polls.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './services/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AllEventsComponent } from './all-events/all-events.component';
import { AllTasksComponent } from './all-tasks/all-tasks.component';
import { UpcomingEventsComponent } from './upcoming-events/upcoming-events.component';
import { AllUsersComponent } from './all-users/all-users.component';
import { EventDetailComponent } from './components/event-detail/event-detail.component';
import { BlockedUsersComponent } from './blocked-users/blocked-users.component';
import { AdminComponent } from './admin/admin.component';
import { ProfileComponent } from './profile/profile.component';

const routes: Routes = [
  {path:'login',component:LoginComponent},
  {path:'dashboard',component:DashboardComponent,canActivate:[AuthGuard]},
  {path:'all-events',component:AllEventsComponent,canActivate:[AuthGuard]},
  {path:'all-tasks',component:AllTasksComponent,canActivate:[AuthGuard]},
  {path:'polls',component:PollsComponent,canActivate:[AuthGuard]},
  {path:'upcoming-events',component:UpcomingEventsComponent,canActivate:[AuthGuard]},
  {path:'all-users',component:AllUsersComponent,canActivate:[AuthGuard]},
  {path:'event-detail/:id',component:EventDetailComponent,canActivate:[AuthGuard]},
  {path:'blocked-users',component:BlockedUsersComponent,canActivate:[AuthGuard]},
  {path:'reported-events',component:ReportedEventsComponent,canActivate:[AuthGuard]},
  {path:'admin',component:AdminComponent,canActivate:[AuthGuard]},
  {path:'profile/:id',component:ProfileComponent,canActivate:[AuthGuard]},
  { path: '', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
