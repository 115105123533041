import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AuthResponse } from '../models/auth.response';
import { Router } from '@angular/router';
import { Auth } from '../models/auth.request';
import { Observable } from 'rxjs';
 
@Injectable({
  providedIn: 'root'
})
export class LoginService extends DataService {  
 
  private tokenKey = 'auth_token';
 
  constructor(http:HttpClient, private router:Router) {
    super(http);
  }
  
 
  /**
   * Authorize user & store the token
   * @param auth
   */
  authorize(auth:Auth){      
    this.post('admin/auth',auth).subscribe({
      next:(response:any) => {        
        if(response.payload?.token != null){
          localStorage.setItem(this.tokenKey,response.payload?.token);
          this.router.navigate(['dashboard']);               
        }
      },error:(error)=>{     
        console.log(error);
        return false;           
      }
    });       
  }
 
  getLoggedInUser(): Observable<any>{
     return this.getAll('admin/profile/current');
  }
 
  /**
   * Logout user
   */
  logout() {
    localStorage.removeItem(this.tokenKey);
  }
 
  /**
   * check if user is authenticated.
   * @returns
   */
  isAuthenticated(): boolean {
    return !!localStorage.getItem(this.tokenKey);
  }
 
}