import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from '../../services/events.service';
import { EventListings } from '../../models/event.listings';
import { DatePipe, Location } from '@angular/common';
import { PollOption } from '../../models/PollOption';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrl: './event-detail.component.css'
})
export class EventDetailComponent implements OnInit {
  datePipe: DatePipe = new DatePipe('en-US');

  id?: string;
  response:EventListings;
  totalVotes:number=0;
  totalPolls:number=0;

  modalRef?: BsModalRef;
  message?: string;  

  constructor(private route:ActivatedRoute,private event:EventsService,private location:Location,private modalService:BsModalService){
    this.id=this.route.snapshot.paramMap.get("id") ?? "";
    this.response = new EventListings();
  }

  ngOnInit(): void {    
    this.event.getEventDetails(this.id).subscribe((event:any)=>{      
      this.response=event.payload;  
      this.totalPolls=(this.response.poll == null) ? 0 : this.response.poll.length;
      this.totalVotes = this.response.poll?.options?.reduce((sum:any, option:any) => sum + option.totalVote, 0);
    });
  }

  formatDate(epochTime: number): string {
    const formattedDate = this.datePipe.transform(new Date(epochTime * 1000), 'EEE, dd MMM yyyy');
    return formattedDate || '';
  }

  formatTime(epochTime: number): string {
    const formattedDate = this.datePipe.transform(new Date(epochTime * 1000), 'hh:mm a');
    return formattedDate || '';
  }


  getPercentage(voteCount: number,totalVotes:number): number {
    if (totalVotes === 0) {
      return 0;
    }
    return (voteCount / totalVotes) * 100;
  }

  getTotalVotes(options:PollOption[]):number{
    return options.reduce((sum, option) => sum + option.totalVote, 0);
  }

  goBack(){
    this.location.back();
  }

  openModal(template: TemplateRef<void>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-ms' });
  }


  confirm(): void {
    this.modalRef?.hide();
    //method to delete event
  
  }
 
  decline(): void {    
    this.modalRef?.hide();
  }

}
