import { Component, OnInit, TemplateRef } from '@angular/core';

import { DatePipe } from '@angular/common';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EventsService } from '../services/events.service';
import { ReportedEvent } from '../models/reported.event';


@Component({
  selector: 'app-reported-events',
  templateUrl: './reported-events.component.html',
  styleUrl: './reported-events.component.css'
})
export class ReportedEventsComponent implements OnInit {

  datePipe: DatePipe = new DatePipe('en-US');
  pageSize:number=50;  
  totalItems = 0;
  currentPage = 0;

  reportedEvents: ReportedEvent[] = [];

  modalRef?: BsModalRef;
  subject?:string
  message?:string;
  blockContent?:string;

  ngOnInit(): void {
    this.loadData(1);
  }

  constructor(private eventService:EventsService,private modalService:BsModalService){}

  loadData(pageNumber:number){
    this.eventService.getReportedEvents(pageNumber,this.pageSize).subscribe(
      (events:any)=>{          
        this.totalItems = events.payload.totalElements;        
        this.currentPage=pageNumber;
        this.reportedEvents = events.payload.elements;        
      }
    );

  }


  pageChanged(event: PageChangedEvent): void {
    this.loadData(event.page);
  }
  setPageSize(event:any){
    this.pageSize = event.target.value;    
    this.loadData(1);
  }

  formatDate(epochTime: number): string {
    const formattedDate = this.datePipe.transform(epochTime, 'EEE, dd MMM yyyy hh:mm a');
    return formattedDate || '';
  }

  formatTime(epochTime: number): string {
    const formattedDate = this.datePipe.transform(epochTime, 'hh:mm a');
    return formattedDate || '';
  }

  openModal(template: TemplateRef<void>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }
  
  block(): void {   
    this.blockContent="";        
    this.modalRef?.hide();
  }
 
  decline(): void {    
    this.modalRef?.hide();
  }


}
