<table class="table table-custom">
    <thead>
        <tr>
            <th>Task</th>
            <th>Event</th>
            <th>Assigned to</th>
            <th>Status</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let task of activeTasks">
            <td>{{task.title}}</td>
            <td>{{task.description}}</td>
            <td>{{task.assignedToName}}</td>
            <td>{{task.taskAcceptance}}</td>
        </tr>                                       
    </tbody>
</table>

<pagination *ngIf="hideIfNotNeeded" [maxSize]="10" [boundaryLinks]="true" [totalItems]="totalItems" [itemsPerPage]="pageSize" (pageChanged)="pageChanged($event)"
previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">

</pagination>