import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PollsService } from '../../services/polls.service';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import { Poll } from '../../models/polls';
import { PollOption } from '../../models/PollOption';


@Component({
  selector: 'app-poll-component',
  templateUrl: './poll-component.component.html',
  styleUrl: './poll-component.component.css'
})
export class PollComponentComponent implements OnInit {

  

  
  polls?:Poll[];

  @Input() totalCols:number=4;
  @Input() totalPolls:number=0;
  public pageSize:number=10;

  totalItems = 0;
  currentPage = 0;
  smallnumPages = 0;

  hideIfNotNeeded:boolean=true;

  constructor(private poll:PollsService){}

  ngOnInit(): void {
    this.loadActiveTasks(1);     
  }

  public loadActiveTasks(pageNumber:number){
    this.poll.getActivePolls(pageNumber,this.pageSize).subscribe(
      (eventPoll:any)=>{                
              
        this.polls = eventPoll.payload.map((pollData:any) => new Poll(pollData));
        if(this.totalPolls == 0)
          this.totalPolls=(this.polls == null) ? 0 : this.polls.length;
       
      }
    );
  }

  pageChanged(event: PageChangedEvent): void {
    this.loadActiveTasks(event.page);
  }

  getPercentage(voteCount: number,totalVotes:number): number {
    if (totalVotes === 0) {
      return 0;
    }
    return (voteCount / totalVotes) * 100;
  }

  getTotalVotes(options:PollOption[]):number{
    return options.reduce((sum, option) => sum + option.totalVote, 0);
  }

}