<div id="pollCarousel" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let poll of polls; let i = index" [class.active]="i === 0">
<div class="row">
    <div class="col-{{totalCols}} mb-5" *ngFor="let poll of polls | slice:0:totalPolls">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">{{poll.question}}</h5>
                <ul class="vote-list">
                    <li *ngFor="let opt of poll.options" class="vote-item">
                        <span class="option-name">{{opt.option}}</span>
                        <span class="percentage">{{getPercentage(opt.totalVote,getTotalVotes(poll.options)) | number:'1.0-2'}}%</span>
                        <div class="progress-bar">
                            <div class="progress" [style.width.%]="getPercentage(opt.totalVote,getTotalVotes(poll.options)) | number:'1.0-2'"></div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>