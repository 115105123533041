<div class="container-fluid p-3">
    <div class="col-12">
      <div class="row">
        <div class="col-8">
          <h6 class="alert p-2 mb-1">{{ userProfile.name }}</h6>
        </div>
        <div class="col">
          <button class="btn btn-info btn-back btn-sm" (click)="goBack()"><i class="bi bi-skip-backward-circle"></i> Back</button>
        </div>
      </div>
    </div>
    <div class="col-12 upcoming-events p-2">
      <div class="row">
        <div class="col-4">
          <!-- Display image -->
          <div class="card">
            <div class="card-body p-1">
              <img *ngIf="userProfile.profileImg; else defaultImage" [src]="userProfile.profileImg" class="img-fluid rounded w-100" alt="">
              <ng-template #defaultImage>
                <img src="assets/images/user_default.png" class="img-fluid rounded w-100" alt="">
              </ng-template>
              <img src="assets/images/business-profile.png" *ngIf="userProfile.businessProfile != null" class="img-fluid business-profile" alt="">
            </div>
          </div> <!-- Display image - close -->
          <button (click)="openModal(disbaleUser)" class="btn btn-danger w-100 mt-3">Disable User</button>
        </div>
        <div class="col">
            <div class="card">
                <div class="card-body p-3">                                  
                    <table class="table">
                        <tr>
                            <th>Name</th>
                            <td>{{userProfile.name}}</td>
                        </tr>
                        <tr>
                            <th>User Name</th>
                            <td>{{userProfile.userName}}</td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td>{{userProfile.email}}</td>
                        </tr>
                        <tr>
                            <th>Mobile</th>
                            <td>{{userProfile.mobile}}</td>
                        </tr>
                        <tr>
                            <th>Gender</th>
                            <td>{{userProfile.gender}}</td>
                        </tr>
                        <tr>
                            <th>Country</th>
                            <td>{{userProfile.country}}</td>
                        </tr>
                        <tr>
                            <th>State</th>
                            <td>{{userProfile.stateName}}</td>
                        </tr>
                        <tr>
                            <th>City</th>
                            <td>{{userProfile.cityName}}</td>
                        </tr>                       
                        <tr>
                            <th>Profile Type</th>
                            <td>
                                {{userProfile.businessProfile == null ? 'Normal User' : 'Business User' }}
                                <img *ngIf="userProfile.businessProfile != null" src="assets/images/business-profile.png" class="img-fluid" alt="">
                            </td>
                        </tr>
                        <tr>
                            <th>Bio</th>
                            <td>{{userProfile.bio}}</td>
                        </tr>                                                                                                                                                                                                                                                
                    </table>
                </div>
              </div>           
        </div>
      </div> 
    </div>
  </div>
  <ng-template #disbaleUser>
    <div class="modal-body">
      <div class="modal-header p-0">
        <h6 class="modal-title pull-left">Disbale User</h6>
      </div>
      <div class="modal-body p-0">
        <div class="row">       
            <div class="col-12 mb-3 mt-2 text-danger">
              Are you sure want to delete user?
            </div>
            <div class="col-6">
              <button (click)="confirm()" class="btn btn-danger btn-sm w-100">Disable User</button>
            </div>
            <div class="col-6">
              <button (click)="decline()" class="btn btn-secondary btn-sm w-100">Cancel</button>
            </div>
        </div>
      </div>
  
    </div>
  </ng-template>
  
