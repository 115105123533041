<div class="container-fluid p-3">
  <div class="row" style="margin: 0 15px 0 0;">
    <h6 class="alert p-2 mb-1">Dashboard</h6>    
        <div class="col-6">
           <div class="row">
            <div class="col-md-6 mb-4">
                <div class="tile">
                  <div class="tile-header">
                    <i class="bi bi-calendar4-week"></i>
                    <a class="name" routerLink="/all-events">Events</a>
                  </div>
                  <div class="tile-content">
                    <span class="number">{{totalEvents | thousandsSeprator}}</span>
                    <div class="row">
                      <div class="col-6">
                        <div class="highlights">
                          <strong>{{totalPastEvents | thousandsSeprator}}</strong>
                          <span> Past Events</span>  
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="highlights">
                          <strong>{{totalUpcomingEvents | thousandsSeprator}}</strong>
                          <span> Upcoming Events</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="tile">
                  <div class="tile-header">
                    <i class="bi bi-list-task"></i>
                    <a class="name" routerLink="/all-tasks">Tasks</a>
                  </div>
                  <div class="tile-content">
                    <span class="number">{{totalTasks | thousandsSeprator}}</span>
                    <div class="row">
                      <div class="col-6">
                        <div class="highlights">&nbsp;</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="tile">
                  <div class="tile-header">
                    <i class="bi bi-person-check-fill"></i>
                    <a class="name" routerLink="/all-users">Users</a>
                  </div>
                  <div class="tile-content">
                    <span class="number">{{totalUsers | thousandsSeprator}}</span>
                    <div class="row">
                      <div class="col-6">
                        <div class="highlights">&nbsp;</div>
                      </div>
                    </div>                    
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-6 mb-4">
                <div class="tile">
                  <div class="tile-header">
                    <i class="bi bi-bar-chart-line"></i>
                    <a class="name" routerLink="/polls">Polls</a>
                  </div>
                  <div class="tile-content">
                    <span class="number">{{totalPolls | thousandsSeprator}}</span>
                    <div class="row">
                      <div class="col-6">
                        <div class="highlights">&nbsp;</div>
                      </div>
                    </div>                    
                  </div>
                </div>
              </div> -->
              <div class="col-md-6">
                <div class="tile">
                  <div class="tile-header">
                    <i class="bi bi-person-fill-slash"></i>
                    <a class="name" routerLink="/blocked-users">Blocked Users</a>
                  </div>
                  <div class="tile-content">
                    <span class="number">{{totalBlockedUsers | thousandsSeprator}}</span>
                    <div class="row">
                      <div class="col-6">
                        <div class="highlights">&nbsp;</div>
                      </div>
                    </div>                    
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="tile">
                  <div class="tile-header">
                    <i class="bi bi-flag-fill"></i>
                    <a class="name" routerLink="/reported-events">Reported Events</a>
                  </div>
                  <div class="tile-content">
                    <span class="number">{{totalReportedEvents | thousandsSeprator}}</span>
                    <div class="row">
                      <div class="col-6">
                        <div class="highlights">&nbsp;</div>
                      </div>
                    </div>                    
                  </div>
                </div>
              </div>
           </div>
        </div>
        <div class="col-6 upcoming-events p-2">
            <div class="row event-row-reset">
                <h6 class="alert m-0 p-1">
                  <a routerLink="/upcoming-events">Upcoming Events</a>
                </h6>
                <div *ngFor="let event of upcomingEvents; let i = index" [ngClass]="{'col-6': i % 2 === 0, 'col col-space': i % 2 !== 0}" class="mb-2 events">                    
                    <div class="row">
                        <div class="col-4">
                            <div class="calendar-bg">
                                <div class="calendar">
                                    <span>{{formatDay(event.eventStartAt)}}</span>
                                    <span>{{formatMonth(event.eventStartAt)}}</span>
                                </div>
                                <span class="time">{{formatTime(event.eventStartAt)}}</span> 
                            </div>                             
                        </div>
                        <div class="col-8 p-0">
                            <a routerLink="/event-detail/{{event.eventGuid}}" class="sub">{{event.title}}</a>
                            <span class="desc">{{event.address}}</span>
                            <div class="row">
                                <div class="col-6">
                                    <div class="highlights"><strong>{{event.taskCount}}</strong><span> Tasks</span></div>
                                </div>
                                <div class="col-6">
                                    <div class="highlights"><strong>{{event.participantCount}}</strong><span> Attendees</span></div>
                                </div>
                            </div>                                                        
                        </div>
                    </div>
                </div>     

            </div>
        </div>    
        <div class="col-12 active-tasks mt-3 p-2">
            <h6 class="alert m-0 p-1">
              <a routerLink="/all-tasks">Active Tasks</a>
            </h6>
            <app-active-tasks [showPagination]="false"></app-active-tasks>
        </div>    
        
        <!-- <div class="col active-tasks mt-3 p-2">
            <h6 class="alert m-0 p-1">
              <a routerLink="/polls">Polls</a></h6>
            <app-poll-component [totalCols]="12" [totalPolls]="2"></app-poll-component>
        </div> -->
        
  </div>
</div>
