import { PollOption } from "./PollOption";

export class Poll {
  pollId: number;
  eventGuid: string | null;
  question: string;
  isVoted: boolean;
  pollStatus: string | null;
  options: PollOption[];

  constructor(data: any) {
    this.pollId = data.pollId;
    this.eventGuid = data.eventGuid;
    this.question = data.question;
    this.isVoted = data.isVoted;
    this.pollStatus = data.pollStatus;
    this.options = data.options.map((option: any) => new PollOption(option));
  }

}
