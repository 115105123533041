import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '../services/users.service';
import { UserProfile } from '../models/auth.profile';
import { Location } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent implements OnInit {

  userId:string;
  userProfile:UserProfile;
  
  modalRef?: BsModalRef;
  message?: string;  

  constructor(private route:ActivatedRoute,private user:UsersService,private location:Location,private modalService:BsModalService){
    this.userId = this.route.snapshot.paramMap.get("id") ?? "";
    this.userProfile = new UserProfile();
  }

  ngOnInit(): void {
    this.loadUser();
  }
  
  goBack(){
    this.location.back();
  }


  private loadUser(){
    this.user.getProfile(this.userId).subscribe(
      (user:any)=>{        
        this.userProfile = user.payload;
      }
    );
  }

  disableUser(){
    this.user.disbleUser(this.userId).subscribe(
      (user:any)=>{        
        this.location.back();
      }
    );
  }

  openModal(template: TemplateRef<void>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }


  confirm(): void {
    this.modalRef?.hide();
    this.disableUser();
  }
 
  decline(): void {    
    this.modalRef?.hide();
  }
  
}
